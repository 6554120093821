

/* src/Footer.css */
.footer {
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1000; /* Ensure it stays on top */
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
}

.footer-left {
  text-align: left;
  padding-left: 20px;
}

.footer-center {
  text-align: center;
}

.footer-right {
  text-align: right;
  padding-right: 20px;
}

.footer-right nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.footer-right nav ul li {
  display: inline;
}

.footer-right nav ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-right nav ul li a:hover {
  text-decoration: underline;
}
